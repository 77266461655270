
import Input from './components/input';
import Footer from './components/footer';
import TutorialCard from './components/totorial_card';
import './App.css';


function App() {
  return (
    
    <div className="App" id="app">
      <h1>WANsearch</h1>
     <Input />
     <Footer />
     <TutorialCard />
    </div>
    
  );
}

export default App;
