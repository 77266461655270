import "./footer.css"

export default function Footer() {
    return (
        <>
            <div className="footer">

                <span >Made with&nbsp;
                 
                    &nbsp;by
                    <a
                        href="https://www.linkedin.com/in/tharusha-jayasooriya-1540b52aa/"
                        target="_blank">&nbsp;Tharusha</a
                    ></span>
                <span >Last updated : 	I'm In The News Again - WAN Show June 28, 2024</span>

            </div>

        </>
    )
}